import gsap from 'gsap';
import { selectorAll } from '../utils/helper/function-helper';
import Util from '../utils/util';

export default class Header {
  private readonly root: HTMLElement | null = null;
  private readonly header: HTMLElement | null = null;
  private readonly headInfo: HTMLElement | null = null;
  private readonly target: HTMLElement | null = null;
  private readonly menuHolder: HTMLElement | null = null;
  private isOpen: boolean;
  private readonly isSticky: boolean = true;

  constructor() {
    this.root = document.getElementById('root');
    this.header = document.getElementById('header');
    this.headInfo = document.querySelector('.header__info');
    this.target = document.getElementById('js-hamburger');
    this.menuHolder = document.getElementById('js-nav');
    this.isOpen = false;
    this.init();
  }

  private init(): void {
    if (!this.target) return;

    this.setActiveMenu();
    this.target.addEventListener('click', this.handleToggle);
    window.addEventListener('load', this.handleSticky);
    window.addEventListener('scroll', this.handleSticky);
    this.handleSubMenu();
  }

  private handleToggle = (): void => {
    this.header?.classList.remove('is-show-sub');
    if (window.innerWidth > 768) return;
    this.isOpen = !this.isOpen;
    this.isOpen ? this.openMenu() : this.closeMenu();
  };

  private handleSticky = (): void => {
    if (!this.header || !this.isSticky) return;

    const shouldBeFixed = window.scrollY > this.getPosSticky();

    const isLocked = document.body.classList.contains('is-lock');

    if (shouldBeFixed) {
      this.root?.classList.add('is-space-top');
      this.header.classList.add('is-fixed');
    } else if (!isLocked) {
      this.root?.classList.remove('is-space-top');
      this.header.classList.remove('is-fixed');
    }
  };



  private getPosSticky = (): number => {
    if (!this.headInfo) return 0;
    return this.headInfo.scrollHeight
  }




  private openMenu(): void {
    if (!this.header || !this.target || !this.menuHolder) return;

    this.isOpen = true;
    this.header.classList.add('is-active');
    this.target.classList.add('is-active');
    this.menuHolder.classList.add('is-active');
  }

  private closeMenu(): void {
    if (!this.header || !this.target || !this.menuHolder) return;
    this.isOpen = false;
    this.target.classList.remove('is-active');
    this.header.classList.remove('is-active');
    this.menuHolder.classList.remove('is-active');
    Util.Dispatcher.dispatchEvent('SCROLL_RELEASE');
    this.menuHolder.scrollTo(0, 0)
  }

  private setActiveMenu(): void {
    const menus = selectorAll('.header__menu .nav__menu a');
    const currentUrl = location.pathname.replace(/(index\.html|index\.htm|index\.php)$/g, '');

    menus.forEach(menu => {

      const href = menu.getAttribute('href')?.replace(/[^/]+(\.html|\.htm|\.php)$/, '');
      if (currentUrl === '/' && currentUrl === href) {
        menu.parentElement?.classList.add('active')
      }
      else if (href && href !== '/' && currentUrl.includes(href)) {
        menu.parentElement?.classList.add('active')
      }
      else {
        menu.parentElement?.classList.remove('active')
      }
    });
  }

  private handleSubMenu = (): void => {
    const subList: HTMLElement[] = selectorAll('.has-sub') || []
    for (const subItem of subList) {
      subItem.addEventListener('click', (e) => {
        if (window.innerWidth > 767) return;
        const contentSub = subItem.querySelector('.menu-sub');
        if (!contentSub) return;
        const subHeight = contentSub.scrollHeight;

        if (subItem.classList.contains('is-active')) {
          gsap.to(contentSub, { height: 0, duration: 0.2, onComplete: () => { subItem.classList.remove('is-active') } })
        }
        else {
          gsap.to(contentSub, { height: subHeight, duration: 0.2, onComplete: () => { subItem.classList.add('is-active') } })
        }

      })
    }
  }


}


