import AnchorLink from './common/anchor-link-custom';
import Header from './common/header';
import Modal from './common/modal';
import DeviceWatcher from './utils/logic/device-watcher';
import ScrollController from './utils/logic/scroll-controller';

new DeviceWatcher();

new ScrollController();

export default class Main {
  constructor() {
    new Header();
    new AnchorLink('#header');
    new Modal();
  }
}
window.addEventListener('DOMContentLoaded', () => {
  new Main();
});
