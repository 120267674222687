import { selectorAll } from "../utils/helper/function-helper"

export default class Modal {
  constructor() {
    this.showPriceQuote();
    this.showCarTest();
    this.handleCloseModal();
  }

  private showPriceQuote = (): void => {
    const buttonsShow: HTMLElement[] | null = selectorAll('.show-price-quote');
    const modal: HTMLElement | null = document.getElementById('price-quote');
    for (const buttonShow of buttonsShow) {
      buttonShow.addEventListener('click', (e) => {
        e.preventDefault();
        if (!modal) return;
        const carList: HTMLSelectElement | null = modal.querySelector('.choose-car')
        const target = e.currentTarget as HTMLElement
        const carSelect = target.dataset.car;

        if (carList) {
          carList.value = String(carSelect);
        }
        modal.classList.add('is-show');

      })
    }
  }

  private showCarTest = (): void => {
    const buttonsShow: HTMLElement[] | null = selectorAll('.show-car-test');
    const modal: HTMLElement | null = document.getElementById('car-test');
    for (const buttonShow of buttonsShow) {
      buttonShow.addEventListener('click', (e) => {
        e.preventDefault();
        if (!modal) return;
        const carList: HTMLSelectElement | null = modal.querySelector('.choose-car')
        const target = e.currentTarget as HTMLElement
        const carSelect = target.dataset.car;
        if (carList && carSelect) {
          carList.value = String(carSelect);
        }
        modal.classList.add('is-show');

      })
    }
  }

  private handleCloseModal = (): void => {
    const buttonsClose: HTMLElement[] | null = selectorAll('.modal-close')
    const backgroundsModal: HTMLElement[] | null = selectorAll('.modal__bg')

    this.close(buttonsClose);
    this.close(backgroundsModal);
  }

  private close = (elms: HTMLElement[] | null): void => {
    if (!elms) return;

    for (const elm of elms) {
      elm.addEventListener('click', (e) => {
        const target: HTMLElement | null = e.currentTarget as HTMLElement;
        const modal = target.closest('.modal')
        if (!modal) return;
        const carList: HTMLSelectElement | null = modal.querySelector('.choose-car');
        modal.classList.remove('is-show');
        if (carList) {
          carList.value = '';
        }
      })
    }
  }
}

